import { defineStore } from 'pinia'
import { TypeImport } from '~/interfaces/other'

const useImportStore = defineStore('import', () => {
    const importLines = (
        context: any,
        payload: {
            attachment: any
            idOrga: number
            idBU: number
            shouldUpdate?: boolean
            type: TypeImport
        }
    ) => {
        const { attachment, idOrga, idBU, shouldUpdate, type } = payload

        let url = ''
        if (type === TypeImport.COSTCENTER) {
            url = `invoicyapi/business-units/${idBU}/cost-centers/batch`
        } else {
            url = `cloudapi/organizations/${idOrga}/business-units/${idBU}/${context.$toKebab(
                `${type}s`
            )}/batch`
        }

        if (shouldUpdate) {
            url += `?should_update=${shouldUpdate}`
        }

        const formData = new FormData()
        formData.append(`${context.$toKebab(type)}s`, attachment)
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(
                    (res: {
                        imported: number
                        ignored: number
                        updated: number
                    }) => {
                        resolve(res)
                    }
                )
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    return {
        importLines
    }
})

type ImportStore = Omit<
    ReturnType<typeof useImportStore>,
    keyof ReturnType<typeof defineStore>
>

export default useImportStore

export type { ImportStore }
