import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AxiosError, AxiosResponse } from 'axios'
import { convertDate } from './charge'
import useFlaggyStore from './flaggy'
import { FilterList } from '@/interfaces/filters'
import { Pass } from '@/interfaces/pass'
import {
    BrandModelVersion,
    CampagneEntryPublic,
    Campaign,
    Entry,
    Mileage,
    Vehicle,
    VehicleJSON
} from '@/interfaces/vehicle'

const useVehicleStore = defineStore('vehicle', () => {
    const defaultVehicle = {
        id: null,
        immatriculation: '',
        additionalInformation: '',
        version: {
            id: 0,
            name: ''
        },
        brand: {
            id: 0,
            name: ''
        },
        model: {
            id: 0,
            name: ''
        },
        passes: [] as Pass[],
        lastMileage: null,
        dateCreated: null,
        dateUpdated: null
    }

    const filtersListing = ref<FilterList>({
        searchField: '',
        pages: {
            index: 1,
            perPage: 20,
            totalItems: 0
        }
    })
    const vehicleEdited = ref<Vehicle>(defaultVehicle)
    const mileageEdited = ref<null | number>(null)
    const refetchData = ref<boolean>(false)
    const showCreateCampaignForm = ref<boolean>(false)
    const vehiclesSelected = ref<number[]>([])
    const allVehiclesIds = ref<number[]>([])
    const loadingUpdateMileage = ref<boolean>(false)
    const loadingPostOrPutVehicleEdited = ref<boolean>(false)
    const lastMileageVehicleEdited = ref<Mileage | null>(null)

    const resetState = () => {
        vehicleEdited.value = { ...defaultVehicle }
        mileageEdited.value = null
        filtersListing.value = {
            searchField: '',
            pages: {
                index: 1,
                perPage: 20,
                totalItems: 0
            }
        }
        vehiclesSelected.value = []
    }

    const formatVehicle = (
        vehicle: Vehicle,
        type: 'update' | 'post' = 'post'
    ) => {
        const vehicleFormatted = {
            immatriculation: vehicle.immatriculation,
            additional_information: vehicle.additionalInformation,
            version_id: vehicle.version?.id || null,
            rfid_passes: vehicle.passes.map((p) => p.id),
            mileage:
                mileageEdited.value ||
                lastMileageVehicleEdited.value?.mileage ||
                undefined
        }

        if (type === 'update') {
            return {
                ...vehicleFormatted,
                mileage: undefined
            }
        }

        return vehicleFormatted
    }

    const getAllVehicles = (
        context: any,
        payload: {
            idBU: number
        }
    ): Promise<Vehicle[]> => {
        const { idBU } = payload
        const offset = 0
        const limit = 9999
        const params = {
            offset,
            limit
        }

        const url = `/mileyapi/business-units/${idBU}/vehicles`

        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: { items: Vehicle[] }) => {
                    const vehicles = context.$toCamel(res.items)
                    return resolve(vehicles)
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const getVehicles = (
        context: any,
        payload: {
            idBU: number
            filters: FilterList
        }
    ): Promise<{ vehicles: Vehicle[]; count: number }> => {
        const { idBU, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || 20
        const keyword = filters.searchField || (null as null | string)
        const orderBy = filters.orderBy || (null as null | string)
        let params = {
            offset,
            limit,
            keyword,
            orderBy,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        const url = `/mileyapi/business-units/${idBU}/vehicles`
        return new Promise((resolve, reject) => {
            context.$axios
                .get(url, { params })
                .then((res: AxiosResponse) => {
                    const vehicles = context.$toCamel(res.data)
                    const count = Number(res.headers['x-total-count'])
                    return resolve({
                        vehicles: vehicles.items,
                        count
                    })
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const getVehicle = (
        context: any,
        payload: { idOrga: number; idBU: number; idVehicle: number }
    ): Promise<Vehicle> => {
        const { idOrga, idBU, idVehicle } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
            context.$axios
                .$get(url)
                .then((res: VehicleJSON) => {
                    const vehicle = context.$toCamel(res)
                    resolve(vehicle)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const createVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { idOrga, vehicle, idBU } = payload
        const vehicleFormatted = formatVehicle(vehicle)
        loadingPostOrPutVehicleEdited.value = true

        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`,
                    vehicleFormatted
                )
                .then((res: VehicleJSON) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
                .finally(() => {
                    loadingPostOrPutVehicleEdited.value = false
                })
        })
    }

    const updateVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormatted = formatVehicle(vehicle, 'update')
        loadingPostOrPutVehicleEdited.value = true

        let api = 'cloudapi'
        if (useFlaggyStore().hasFeatureActivated('mileageReports')) {
            api = 'mileyapi'
        }

        return new Promise((resolve, reject) => {
            context.$axios
                .$put(
                    `/${api}/organizations/${idOrga}/business-units/${idBU}/vehicles/${vehicle.id}`,
                    vehicleFormatted
                )
                .then((res: VehicleJSON) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
                .finally(() => {
                    loadingPostOrPutVehicleEdited.value = false
                })
        })
    }

    const deleteVehicle = (
        context: any,
        payload: { idVehicle: number; idOrga: number; idBU: number }
    ) => {
        const { idVehicle, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$delete(url)
                .then((res: AxiosResponse) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const getBrands = (context: any, payload: { searchField: string }) => {
        const params = {
            keyword: payload.searchField
        }
        const url = `/cloudapi/vehicles/brands`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: AxiosResponse<BrandModelVersion>) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const getModels = (
        context: any,
        payload: { idBrand: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: AxiosResponse<BrandModelVersion>) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const getVersions = (
        context: any,
        payload: { idBrand: number; idModel: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand, idModel } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models/${idModel}/versions`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: AxiosResponse<BrandModelVersion>) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const fetchMileageReport = (
        context: any,
        payload: {
            idBU: number
            idVehicle: number
        }
    ) => {
        const { idBU, idVehicle } = payload

        const url = `/mileyapi/business-units/${idBU}/vehicles/${idVehicle}/mileage-report`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url)
                .then((res: { lastMileage: Mileage }) => {
                    const { lastMileage } = context.$toCamel(res)
                    mileageEdited.value = lastMileage?.mileage || null
                    lastMileageVehicleEdited.value = lastMileage
                    resolve(context.$toCamel(res))
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const fetchMileageReports = (
        context: any,
        payload: {
            idBU: number
            idVehicle: number
            filters: FilterList
        }
    ): Promise<{ items: Mileage[]; totalCount: number }> => {
        const { idBU, filters, idVehicle } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || 20
        const params = {
            offset,
            limit
        }

        const url = `/mileyapi/business-units/${idBU}/vehicles/${idVehicle}/mileage-reports`
        return new Promise((resolve, reject) => {
            context.$axios
                .get(url, { params })
                .then((res: AxiosResponse<{ items: Mileage }>) => {
                    const items = context.$toCamel(res.data.items)
                    const totalCount = Number(res.headers['x-total-count'])
                    return resolve({ items, totalCount })
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const createMileageReport = (
        context: any,
        payload: {
            idBU: number
            idVehicle: number
            mileage: number
        }
    ) => {
        const { idBU, idVehicle, mileage } = payload

        loadingUpdateMileage.value = true
        const url = `/mileyapi/business-units/${idBU}/vehicles/${idVehicle}/mileage-reports`
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(url, { mileage })
                .then((res: Mileage) => resolve(res))
                .catch((e: AxiosError) => reject(e))
                .finally(() => {
                    loadingUpdateMileage.value = false
                })
        })
    }

    const createCampaign = (
        context: any,
        payload: {
            idBU: number
            vehicles: number[]
        }
    ) => {
        const { idBU, vehicles } = payload
        const url = `/mileyapi/business-units/${idBU}/campaigns`
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(url, { vehicle_ids: vehicles })
                .then((res: Campaign) => resolve(res))
                .catch((e: AxiosError) => reject(e))
        })
    }

    const fetchCampaigns = (
        context: any,
        payload: {
            idBU: number
        }
    ): Promise<{ items: Campaign[]; totalCount: number }> => {
        const { idBU } = payload

        const url = `/mileyapi/business-units/${idBU}/campaigns`
        return new Promise((resolve, reject) => {
            context.$axios
                .get(url)
                .then((res: AxiosResponse<{ items: Campaign }>) => {
                    const items = context.$toCamel(res.data.items)
                    const totalCount = Number(res.headers['x-total-count'])
                    return resolve({ items, totalCount })
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const fetchCampaignEntries = (
        context: any,
        payload: {
            idBU: number
            idCampaign: number
        }
    ): Promise<{ items: Entry[]; totalCount: number }> => {
        const { idBU, idCampaign } = payload

        const url = `/mileyapi/business-units/${idBU}/campaigns/${idCampaign}/campaign-entries`
        return new Promise((resolve, reject) => {
            context.$axios
                .get(url)
                .then((res: AxiosResponse<{ items: CampagneEntryPublic }>) => {
                    const items = context.$toCamel(res.data.items)
                    const totalCount = Number(res.headers['x-total-count'])
                    return resolve({ items, totalCount })
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const fetchCampaignEntry = (
        context: any,
        payload: {
            idBU: number
            idEntry: number
            idCollaborator: number
            token: string
        }
    ): Promise<CampagneEntryPublic> => {
        const { idBU, idEntry, token, idCollaborator } = payload

        const url = `/mileyapi/business-units/${idBU}/campaign-entries/${idEntry}`

        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, {
                    params: {
                        collaborator_id: idCollaborator,
                        access_token: token
                    }
                })
                .then((res: CampagneEntryPublic) =>
                    resolve(context.$toCamel(res))
                )
                .catch((e: AxiosError) => reject(e))
        })
    }

    const updateMileageCampaignEntry = (
        context: any,
        payload: {
            idBU: number
            idEntry: number
            mileage: number
            idCollaborator: number
            token: string
        }
    ) => {
        const { idEntry, idBU, mileage, idCollaborator, token } = payload

        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/mileyapi/business-units/${idBU}/campaign-entries/${idEntry}/mileage-reports?access_token=${token}`,
                    {
                        mileage,
                        collaborator_id: idCollaborator
                    }
                )
                .then((res: VehicleJSON) => {
                    resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const closeCampaign = (
        context: any,
        payload: {
            idBU: number
            idCampaign: number
            closedDate: string
        }
    ) => {
        const { idBU, idCampaign, closedDate } = payload

        const url = `/mileyapi/business-units/${idBU}/campaigns/${idCampaign}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$patch(url, {
                    closed_date: closedDate
                })
                .then((res: Campaign) => {
                    const campaign = context.$toCamel(res)
                    return resolve(campaign)
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const reminderCampaign = (
        context: any,
        payload: {
            idBU: number
            idCampaign: number
            idEntry: number
        }
    ) => {
        const { idBU, idCampaign, idEntry } = payload
        const url = `/mileyapi/business-units/${idBU}/campaigns/${idCampaign}/reminder`
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(url, { campaign_entry_id: idEntry })
                .then((res: Campaign) => {
                    return resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    const updatePassFromVehicle = (
        context: any,
        payload: {
            idBU: number
            idOrga: number
            idPass: number
            idCollaborator: number
        }
    ) => {
        const { idBU, idOrga, idPass, idCollaborator } = payload
        const url = `/mileyapi/organizations/${idOrga}/business-units/${idBU}/passes/${idPass}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$patch(url, { collaborator_id: idCollaborator })
                .then((res: Pass) => {
                    return resolve(res)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })
    }

    return {
        getVehicles,
        getVehicle,
        createVehicle,
        updateVehicle,
        deleteVehicle,
        getBrands,
        getModels,
        getVersions,
        getAllVehicles,
        resetState,
        fetchMileageReports,
        createCampaign,
        fetchCampaigns,
        fetchCampaignEntries,
        reminderCampaign,
        closeCampaign,
        fetchCampaignEntry,
        updateMileageCampaignEntry,
        createMileageReport,
        fetchMileageReport,
        updatePassFromVehicle,
        allVehiclesIds,
        vehicleEdited,
        mileageEdited,
        refetchData,
        showCreateCampaignForm,
        filtersListing,
        vehiclesSelected,
        defaultVehicle,
        loadingUpdateMileage,
        loadingPostOrPutVehicleEdited,
        lastMileageVehicleEdited
    }
})

type VehicleStore = Omit<
    ReturnType<typeof useVehicleStore>,
    keyof ReturnType<typeof defineStore>
>

export default useVehicleStore

export type { VehicleStore }
