
import Vue from 'vue'
import { mapActions, mapState } from 'pinia'
import AppMenu from '~/components/UI/AppMenu.vue'
import IconCloseMenu from '~/components/UI/icons/layout/IconCloseMenu.vue'
import useFlaggyStore from '~/store/flaggy'
import useOrganizationStore from '~/store/organization'
import useBusinessUnitStore from '~/store/businessunit'
import useSessionStore from '~/store/session'

export default Vue.extend({
    components: { AppMenu, IconCloseMenu },
    middleware: ['auth', 'init', 'navigation', 'flaggyConfigured'],
    data: () => ({
        heartbeatListenerId: 0,
        mobileMenuOpen: false,
        selectedMenu: 'administration'
    }),
    computed: {
        ...mapState(useSessionStore, [
            'user',
            'isSuperAdmin',
            'isAdmin',
            'isBUAdmin',
            'isSupervisionManager',
            'isFleetManager'
        ]),
        currentRole(): string {
            if (this.isSuperAdmin) {
                return 'Super Admin'
            }
            if (this.isAdmin) {
                return 'Orga Admin'
            }
            if (this.isBUAdmin) {
                return 'BU Admin'
            }
            if (this.isFleetManager) {
                return 'Fleet Manager'
            }
            if (this.isSupervisionManager) {
                return 'Supervision Manager'
            }

            return 'Inconnu'
        }
    },
    watch: {
        $route(to, from) {
            // when we completely change page, we scroll to top
            if (to.path !== from.path) {
                this.$nextTick(() => {
                    if (this.$refs.content) {
                        ;(this.$refs.content as HTMLElement).scrollTop = 0
                    }
                })
            }

            // when we change hash, we scroll under the title
            if (to.path === from.path && to.hash !== from.hash) {
                this.$nextTick(() => {
                    if ((this.$refs.content as HTMLElement).scrollTop > 156) {
                        ;(this.$refs.content as HTMLElement).scrollTop = 156
                    }
                })
            }

            // Every time we change route, we push current attributes to JIMO
            this.pushAttributesToJimo()
        }
    },
    async mounted(this: any) {
        this.$menu.$on('toggle', (force: boolean) => {
            if (force === false || (this.mobileMenuOpen && force !== true)) {
                this.closeMobileMenu()
            } else {
                this.mobileMenuOpen = true
            }
        })
        await this.launchFrontAppChat()
        this.launchJimo()
    },
    beforeDestroy(this: any) {
        this.$menu.$off('toggle')
        clearInterval(this.heartbeatListenerId)
    },
    methods: {
        ...mapActions(useFlaggyStore, ['hasFeatureActivated']),
        heartbeat(this: any) {
            return this.$autobahn
                .publish('staying.alive', [], undefined, undefined)
                .catch((error: any) => {
                    this.$sentry.captureException('staying.alive', error)
                })
        },
        closeMobileMenu() {
            this.mobileMenuOpen = false
        },
        switchMenu(id: string) {
            this.selectedMenu = id
        },
        goTo(location: string) {
            this.$router.push(location)
            this.mobileMenuOpen = false
        },
        launchJimo() {
            try {
                const windowHDLD = window

                // If JIMO script is not already loaded and user is logged in
                if (!windowHDLD.jimo && this.user) {
                    // We call the Server API to retrieve private JIMO PROJECT ID and then we load the JIMO script
                    return this.$axios
                        .$get('/server/getInfoForJimo')
                        .then((response: { JimoProjectID: string }) => {
                            windowHDLD.jimo = []
                            const s = document.createElement('script')
                            s.type = 'text/javascript'
                            s.async = true
                            s.src =
                                'https://undercity.usejimo.com/jimo-invader.js'

                            windowHDLD.JIMO_PROJECT_ID = response.JimoProjectID
                            document
                                .getElementsByTagName('head')[0]
                                .appendChild(s)

                            // Set User Email
                            windowHDLD.jimo.push([
                                'set',
                                'user:email',
                                [this.user?.email || '']
                            ])

                            // SET User ID
                            windowHDLD.jimo.push([
                                'do',
                                'identify',
                                [
                                    (this.user?.id || 0).toString(),
                                    () => {
                                        windowHDLD.jimo.push([
                                            'set',
                                            'user:name',
                                            [this.user?.fullName || '']
                                        ])
                                    }
                                ]
                            ])
                            this.pushAttributesToJimo()
                        })
                        .catch(() => {
                            this.$sentry.captureMessage('Jimo error')
                        })
                }
            } catch (e) {
                this.$sentry.captureMessage('Jimo error')
            }
        },
        pushAttributesToJimo() {
            const windowHDLD = window
            if (windowHDLD.jimo) {
                // We add these attributes to JIMO script
                windowHDLD.jimo.push([
                    'set',
                    'user:attributes',
                    [
                        {
                            idOrga: this.$route.params.idOrga,
                            idBU: this.$route.params.idBU,
                            hasMileageReportsActivated:
                                this.hasFeatureActivated('mileageReports'),
                            role: this.currentRole,
                            package: useFlaggyStore().plan,
                            userFirstName: this.user?.firstName || '',
                            userLastName: this.user?.lastName || ''
                        },
                        true
                    ]
                ])
            }
        },
        launchFrontAppChat() {
            const isBrowser = typeof window !== 'undefined'
            if (!this.user?.email || !isBrowser) {
                return
            }
            return this.$axios
                .$get('/server/getInfoForFrontAppChat', {
                    params: {
                        userEmail: this.user.email
                    }
                })
                .then((response: { userHash: string; frontChatID: string }) => {
                    window?.FrontChat('init', {
                        chatId: response.frontChatID,
                        useDefaultLauncher: true,
                        name: this.user?.fullName || '',
                        userHash: response.userHash,
                        email: this.user?.email || '',
                        contact: {
                            customFields: {
                                bu_name:
                                    useBusinessUnitStore().businessUnit?.name ||
                                    '',
                                orga_name:
                                    useOrganizationStore().organization?.name ||
                                    '',
                                phone: this.user?.phone || '',
                                package: useFlaggyStore().plan,
                                locale: this.$i18n.locale,
                                role: this.currentRole
                            }
                        }
                    })
                })
                .catch(() => {
                    this.$sentry.captureMessage('Front chat error')
                })
        }
    }
})
