export enum Plan {
    LEGACY = 'LEGACY',
    CLASSIC = 'CLASSIC',
    ENTERPRISE = 'ENTERPRISE',
    ADVANCED = 'ADVANCED'
}

export interface Feature {
    adminReadonly: boolean
    activated: boolean
}

export interface FeatureDTO {
    admin_readonly: boolean
    activated: boolean
}

export interface Price {
    annual: number
    prorata: number
}

export interface PackagePrice {
    default: string
    max: string
    min: string
}

export interface Options {
    options: {
        callForFunds: boolean
        billOtherBus: boolean
        costAllocation: boolean
    }
}

export interface OptionsDTO {
    options: {
        call_for_funds: boolean
        bill_other_bus: boolean
        cost_allocation: boolean
    }
}

export interface Features {
    features: {
        multipleBus: Feature
        blockingPass: Feature
        callForFunds: Feature
        callForFundsTab: Feature
        bankTransfer: Feature
        creditCard: Feature
        costAllocation: Feature
        debit: Feature
        export: Feature
        billOtherBus: Feature
        import: Feature
        passesQuotaReachedAlert: Feature
        orderNumber: Feature
        canEditPrices: Feature
        multipleUsers: Feature
        orders: Feature
        passActivation: Feature
        overstayParkingFees: Feature
        externalApiConnection: Feature
        chorusApiConnection: Feature
        controlledEnergyTariff: Feature
        mileageReports: Feature
        advancedReducedTariff: Feature
    }
}

export interface FeaturesDTO {
    features: {
        multiple_bus: FeatureDTO
        blocking_pass: FeatureDTO
        call_for_funds: FeatureDTO
        call_for_funds_tab: FeatureDTO
        bank_transfer: FeatureDTO
        credit_card: FeatureDTO
        cost_allocation: FeatureDTO
        debit: FeatureDTO
        export: FeatureDTO
        bill_other_bus: FeatureDTO
        import: FeatureDTO
        passes_quota_reached_alert: FeatureDTO
        order_number: FeatureDTO
        can_edit_prices: FeatureDTO
        multiple_users: FeatureDTO
        orders: FeatureDTO
        pass_activation: FeatureDTO
        overstay_parking_fees: FeatureDTO
        external_api_connection: FeatureDTO
        chorus_api_connection: FeatureDTO
        controlled_energy_tariff: FeatureDTO
        mileage_reports: FeatureDTO
        advanced_reduced_tariff: FeatureDTO
    }
}

export interface Contract extends Features, Options {
    package: Plan
    date: string
    id: number
    adminBlocked: boolean
    organizationId: number
    dateDeleted: string | null
    renewableDate: string
    renewable: boolean
    prices: {
        passOrder: number
        passOrderInternational: number
        passLicense: Price
        chargeBoxLicense: Price
        chargeBoxRebillingLicense: Price
        chargeBoxDomesticLicense: Price
        simLicense: Price
        overstayParkingFeesLicense: Price
    }
}

export interface Package extends Features, Options {
    prices: {
        chargeBoxDomesticPriceRange: PackagePrice
        chargeBoxLicensePriceRange: PackagePrice
        chargeBoxRebillingLicensePriceRange: PackagePrice
        overstayParkingFeesPriceRange: PackagePrice
        passLicensePriceRange: PackagePrice
        passOrderInternationalPriceRange: PackagePrice
        passOrderPriceRange: PackagePrice
        simLicensePriceRange: PackagePrice
    }
}

export interface PackageDTO extends FeaturesDTO, OptionsDTO {
    prices: {
        charge_box_domestic_price_range: PackagePrice
        charge_box_license_price_range: PackagePrice
        charge_box_rebilling_license_price_range: PackagePrice
        overstay_parking_fees_price_range: PackagePrice
        pass_license_price_range: PackagePrice
        pass_order_international_price_range: PackagePrice
        pass_order_price_range: PackagePrice
        sim_license_price_range: PackagePrice
    }
}

export interface ContractDTO extends FeaturesDTO, OptionsDTO {
    package: Plan
    date: string
    id: number
    organization_id: number
    admin_blocked: boolean
    date_deleted: string | null
    renewable_date: string
    renewable: boolean
    prices: {
        pass_order: number
        pass_order_international: number
        pass_license: Price
        charge_box_license: Price
        charge_box_rebilling_license: Price
        charge_box_domestic_license: Price
        sim_license: Price
        overstay_parking_fees_license: Price
    }
}
