import { defineStore } from 'pinia'
import { AxiosError, AxiosResponse } from 'axios'
import { CostCenter } from '~/interfaces/businessunit'

const useCostCenterStore = defineStore('costCenter', () => {
    const getCostCenters = (
        context: any,
        payload: { idBU: number; filters: any }
    ) => {
        const { index, perPage, search } = payload.filters
        const offset = (index - 1) * perPage || (0 as number)
        const limit = perPage || 20
        const keyword = search || null
        const params = {
            offset,
            limit,
            keyword
        }

        const { idBU } = payload
        return context.$axios
            .get(`/invoicyapi/business-units/${idBU}/cost-centers`, { params })
            .then((res: AxiosResponse) => {
                const count = Number(res.headers['x-total-count'])
                const items = context.$toCamel(res.data.items) as CostCenter[]
                return { items, count }
            })
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const postCostCenter = (
        context: any,
        payload: { idBU: number; costCenterName: string }
    ) => {
        const { idBU, costCenterName } = payload
        return context.$axios
            .$post(`/invoicyapi/business-units/${idBU}/cost-centers`, {
                name: costCenterName
            })
            .then((res: any) => res)
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const updateCostCenter = (
        context: any,
        payload: { idBU: number; costCenter: CostCenter }
    ) => {
        const { idBU, costCenter } = payload
        return context.$axios
            .$put(
                `/invoicyapi/business-units/${idBU}/cost-centers/${costCenter.id}`,
                { name: costCenter.name }
            )
            .then((res: any) => res)
            .catch((e: AxiosError) => {
                throw e
            })
    }

    const deleteCostCenter = (
        context: any,
        payload: { idBU: number; costCenter: CostCenter }
    ) => {
        const { idBU, costCenter } = payload
        return context.$axios
            .$delete(
                `/invoicyapi/business-units/${idBU}/cost-centers/${costCenter.id}`
            )
            .then((res: any) => res)
            .catch((e: AxiosError) => {
                throw e
            })
    }

    return {
        getCostCenters,
        postCostCenter,
        updateCostCenter,
        deleteCostCenter
    }
})

type CostCenterStore = Omit<
    ReturnType<typeof useCostCenterStore>,
    keyof ReturnType<typeof defineStore>
>

export default useCostCenterStore

export type { CostCenterStore }
